<template>
    <div  class="body_wrapper">
    <!--     <header-nave :setting="setting" /> -->

      <transition name="page" mode="out-in">
          <router-view />

          
        </transition> 
       <!--  <footer-nave  :setting="setting"/> -->
    </div>
</template>

<script>
// import headerTop from "./components/header";
// import footerNav from "./components/footer";
import { mapActions, mapGetters } from "vuex";
 // import { WOW } from 'wowjs/dist/wow.min.js';
 //  new WOW({ offset: 100, mobile: true }).init();
//import './assets/css/front.css';

/*import '@/sass/themify-icon/themify-icons.css';
import '@/sass/font-awesome/css/all.css';
import '@/sass/responsive.css';
import '@/sass/rtl.css';*/
//import './assets/css/style1.css';

// const headerTop=() => import("./components/header")
// const footerNav=() => import("./components/footer")


export default {
    name: "master",

    components : {
        // "header-nave":headerTop,
        // "footer-nave":footerNav, 
    },
     computed: {
      // setting(){
      //   return this.$store.getters['setting/AppSetting'];
      // }
   // ...mapGetters(["AppSetting"])
  },
    watch: {
        $route (to, from) {
            if (to.meta.title && to.meta && typeof to.meta.title != 'undefined')
                document.title = to.meta.title
        }
    },
       beforeMounted() {
             //  this.$store.dispatch('setting/getSettings');
          },
    mounted() {
      // this.imgLayload();
      //  this.$store.dispatch('setting/getSettings');
     
    }

}
</script>
